.home-banner-first {
  margin-top: 79.6px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  h2 {
    font-size: 50px;
    color: #fff;
  }
  .container_2 {
    img {
      width: 400px;
      height: 400px;
    }
  }
}
.home-banner-sec {
  min-height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 200px;
  img {
    width: 350px;
  }
  .social_media_card {
    display: flex;
    gap: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .home-banner-first {
    flex-direction: column;
    width: fit-content;
    gap: 0;
    .container_1 {
      margin: 10px;
      text-align: center;
      margin-top: 50px;
    }
  }
}

.package-details-sec {
  padding-bottom: 0px;
  .packages-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0px -20px;
    position: relative;
    z-index: 1;
    list-style-type: none;
    > li {
      padding: 30px 20px;
      border-radius: 30px;
      width: calc(100% / 4 - 40px);
      margin: 0px 20px;
      margin-top: 70px;
      border: 2px solid #7f7f7f;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: scale(1);
      transition: 0.3s;
      position: relative;
      cursor: pointer;
      .package-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 99;
      }
      .lock-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        margin: 0;
        z-index: 99;
        img {
          max-width: 30%;
          max-height: 30%;
        }
      }
      h4 {
        text-transform: uppercase;
        text-align: center;
        font-size: 25px;
        line-height: 1.2;
        font-weight: 700;
        transition: 0.3s;
        color: #ff5555;
        font-weight: 900;
      }
      div.packaimg-icon {
        margin: 10px 0px;
        width: 70%;
        padding: 20px 30px;
        border-radius: 50%;
        transition: 0.3s;
        img {
          max-height: 130px;
          transition: 0.3s;
        }
      }
      ul {
        list-style-type: none;
        padding: 0px 20px;
        li {
          font-size: 16px;
          line-height: 22px;
          word-break: break-word;
          font-weight: 700;
          color: #000000;
          transition: 0.3s;
        }
      }
      .view-details-btn {
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin-top: 15px;
        font-weight: 700;
        transition: 0.3s;
      }
      .pricing-btn {
        padding: 3px;
        margin-top: 25px;
        background: rgba(0, 0, 0, 0.5);
        width: 200px;
        height: 60px;
        text-transform: uppercase;
        border-radius: 40px;
        border: 3px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        font-size: 22px;
        line-height: 1.2;
        position: relative;
        transition: 0.3s;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          line-height: 1;
          margin-left: 5px;
        }
        &::after {
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          background: transparent;
          position: absolute;
          top: -2px;
          left: -2px;
          content: '';
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 40px;
        }
      }
      &:hover {
        background: #ff5555;
        color: #fff;
        border-color: rgba($color: #fff, $alpha: 0.3);
        box-shadow: 0px 0px 8px #f51c1e, -0px -0px 8px #f51c1e;
        transform: scale(1.1);
        transition: 0.3s;
        h4 {
          color: #fff;
        }
        div.packaimg-icon {
          background-color: #f51c1e;
          img {
            filter: brightness(0) invert(1);
          }
        }
        .pricing-btn {
          background: #000 url('../../images/gradiant-bg.png') no-repeat center center/cover;
          border-color: #ae3030;
        }
        ul {
          li {
            color: #fff;
          }
        }
        .view-details-btn {
          color: #fff;
        }
        .pricing-btn {
          &::after {
            display: none;
          }
        }
      }
    }
    > li.lock-item {
      opacity: 0.3;
      cursor: not-allowed;
      // &:after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba($color: #000000, $alpha: 0.5); content: ''; border-radius: 27px; }
      &:hover {
        background: transparent;
        border-color: #7f7f7f;
        box-shadow: none;
        transform: scale(1);
        div.packaimg-icon {
          background-color: transparent;
          img {
            filter: none;
          }
        }

        ul {
          li {
            color: #000;
          }
        }
        .view-details-btn {
          color: #000;
        }

        h4 {
          color: #ff5555;
        }
        .pricing-btn {
          background: rgba(0, 0, 0, 0.5);
          border-color: #fff;
          &::after {
            display: block;
          }
        }
      }
    }
  }
  .view-more-btn {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .query-text {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 900;
    color: #000;
    a {
      color: #000;
      &:hover {
        color: #f51c1e;
      }
    }
  }
}
.play-now-btn-sec {
  cursor: pointer;
  a {
    transform: scale(1);
    transition: 0.3s;
    display: inline-block;
    img {
      max-width: 380px;
    }
    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
}
.package-list-sec {
  padding: 30px 0px;
}
.my-games-sec {
  margin-top: 0;
  padding: 30px 0px;
  &::after {
    display: none;
  }
  &::before {
    display: none;
  }
  .game-list {
    li {
      border: 2px solid #aea4a4;
      margin-bottom: 40px;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .play-now-btn-sec {
    a {
      img {
        max-width: 300px;
      }
    }
  }
  .games-sec {
    .game-list {
      li {
        .game-list-header {
          img {
            max-width: 70px;
            margin-right: 10px;
          }
          h3 {
            font-size: 22px;
            width: calc(100% - 90px);
          }
        }
        > h3 {
          font-size: 35px;
        }
      }
    }
  }
  .package-details-sec {
    .packages-list {
      > li {
        ul {
          li {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home-banner-sec {
    h1 {
      font-size: 85px;
    }
    form {
      h3 {
        font-size: 50px;
        margin-bottom: 15px;
      }
    }
  }
  .features-sec {
    padding: 100px 0px 50px 0px;
    p {
      font-size: 20px;
    }
  }
  .games-sec {
    .game-list {
      li {
        width: calc(50% - 50px);
        margin-bottom: 25px;
      }
    }
  }
  .package-details-sec {
    .packages-list {
      > li {
        width: calc(50% - 40px);
        div.packaimg-icon {
          width: 50%;
        }
      }
    }
  }
  .play-now-btn-sec {
    a {
      img {
        max-width: 250px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home-banner-sec {
    h1 {
      font-size: 60px;
    }
    form {
      padding: 20px 15px;
      .form-grp {
        input {
          font-size: 18px;
          padding: 10px 18px;
        }
        .eye-icon {
          top: 10px;
        }
      }
      button {
        max-width: 170px;
        height: 50px;
        span {
          font-size: 20px;
          line-height: 30px;
        }
      }
      h3 {
        font-size: 40px;
        margin-bottom: 15px;
      }
    }
  }
  .features-sec {
    padding: 150px 0px 0px 0px;
    p {
      font-size: 20px;
    }
    .feature-slider {
      .slick-slide {
        padding: 15px 10px;
      }
    }
  }
  .games-sec {
    .game-list {
      li {
        width: calc(50% - 50px);
        margin-bottom: 25px;
        > h3 {
          font-size: 30px;
        }
        .lock-img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .package-details-sec {
    .packages-list {
      > li {
        width: calc(50% - 40px);
        margin-top: 40px;
        div.packaimg-icon {
          width: 65%;
        }
      }
    }
    .query-text {
      font-size: 20px;
    }
  }
  .play-now-btn-sec {
    a {
      img {
        max-width: 220px;
      }
    }
  }
}
@media (max-width: 767px) {
  .home-banner-sec {
    h1 {
      font-size: 60px;
    }
    form {
      padding: 20px 15px;
      margin-top: 30px;
      .form-grp {
        input {
          font-size: 18px;
          padding: 10px 18px;
        }
        .eye-icon {
          top: 12px;
          max-width: 30px;
        }
      }
      button {
        max-width: 170px;
        height: 50px;
        span {
          font-size: 20px;
          line-height: 30px;
        }
      }
      h3 {
        font-size: 40px;
        margin-bottom: 15px;
      }
    }
  }
  .features-sec {
    padding: 120px 0px 0px 0px;
    margin-top: -100px;
    p {
      font-size: 16px;
      line-height: 24px;
    }
    .feature-slider {
      margin-top: 30px;
      padding-bottom: 50px;
      .slick-slide {
        padding: 15px 10px;
      }
      .feature-slide {
        height: auto;
        padding-top: 100%;
      }
      .slick-dots {
        bottom: 20px;
        li {
          width: 40px;
          button {
            height: 3px;
          }
        }
      }
    }
  }
  .games-sec {
    padding: 100px 0px 140px 0px;
    .game-list {
      margin: 0;
      margin-top: 30px;
      padding: 0;
      li {
        width: calc(100%);
        margin: 0;
        margin-bottom: 25px;
        &:hover {
          transform: scale(1);
        }
        > h3 {
          font-size: 30px;
          margin-bottom: 0;
        }
        .lock-img {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
  .package-details-sec {
    .packages-list {
      margin: 0;
      padding: 0;
      justify-content: center;
      > li {
        width: calc(100%);
        margin: 0;
        margin-top: 40px;
        max-width: 300px;
        h4 {
          margin-bottom: 0;
        }
        div.packaimg-icon {
          margin: 10px 0px;
        }
      }
    }
    .query-text {
      font-size: 16px;
    }
    .view-more-btn {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .my-games-sec,
  .package-list-sec {
    padding: 30px 0px;
  }
  .play-now-btn-sec {
    margin-top: 30px;
    a {
      img {
        max-width: 220px;
      }
    }
  }
}

.game-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
