nav.navbar {
  padding: 11px 0px;
  height: 79.6px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  -webkit-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  .navbar-nav {
    margin-left: auto;
    cursor: pointer;
    .nav-item {
      margin: 0px 25px;
      a {
        color: #fff;
        letter-spacing: -0.01em;
        color: #fd5555;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-decoration: none;
        position: relative;
        transition: 0.3s;
        &:before {
          position: absolute;
          top: 200%;
          left: 50%;
          transform: translateX(-50%);
          width: 150%;
          height: 6px;
          background: #210404;
          border-radius: 8px;
          content: '';
          max-width: 0;
          transition: 0.3s;
        }
        &:hover {
          color: #210404;
          transition: 0.3s;
          &::before {
            max-width: 150%;
            transition: 0.3s;
          }
        }
      }
    }
    .nav-item.active {
      a {
        color: #210404;
        transition: 0.3s;
        font-weight: 700;
        text-transform: uppercase;
        // &::before {
        //   max-width: 150%;
        //   transition: 0.3s;
        // }
      }
    }
  }

  .navbar-brand {
    cursor: pointer;
    img {
      max-width: 50px;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .login-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
      display: inline-block;
      font-weight: 700;
    }
  }
  .logout-img {
    display: inline-flex;
    align-items: center;
    max-width: 20px;
    position: relative;
    // top: 25px;
    // right: 25px;
    transform: scale(1);
    transition: 0.3s;
    cursor: pointer;
    img {
      max-width: 100%;
    }
    .tootltip-text {
      position: absolute;
      top: 100%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      background: #fd5555;
      color: #fff;
      padding: 3px 5px;
      border-radius: 7px;
      font-size: 12px;
    }
    &:hover {
      transform: scale(1.15);
      transition: 0.3s;
      .tootltip-text {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-brand {
    img {
      max-width: 150px;
    }
  }
  nav.navbar {
    .navbar-nav {
      padding-right: 30px;
      .nav-item {
        margin: 0px 15px;
        a {
          font-size: 20px;
          line-height: 20px;
          &::before {
            top: 205%;
          }
        }
      }
      .nav-item.languege-dropdown a {
        &::after {
          top: 15px;
        }
      }
    }
    .logout-img {
      max-width: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-brand {
    img {
      max-width: 170px;
    }
  }
  nav.navbar {
    .navbar-nav {
      align-items: center;
      padding-right: 30px;
      .nav-item {
        margin: 0px 15px;
        a {
          font-size: 18px;
          line-height: 20px;
          padding: 0;
          &::before {
            top: 230%;
          }
        }
      }
      .nav-item.languege-dropdown a {
        &::after {
          top: 15px;
        }
      }
    }
    .logout-img {
      max-width: 20px;
    }
  }
}
@media (min-width: 768px) {
  nav.navbar {
    .navbar-collapse {
      display: inline-flex !important;
    }
  }
}
@media (max-width: 767px) {
  .navbar-brand {
    img {
      max-width: 170px;
    }
  }

  nav.navbar {
    .login-dropdown {
      position: absolute;
      top: 25px;
      right: 60px;
      span {
        position: relative !important;
        transform: none !important;
        top: 0 !important;
        background: transparent !important;
      }
    }
    .navbar-nav {
      .nav-item {
        margin: 0px 10px;
        a {
          font-size: 18px;
          line-height: 22px;
          font-weight: 900;
        }
      }
    }
    .logout-img {
      max-width: 20px;
    }
  }
  .navbar {
    .container-fluid {
      padding: 0;
    }
    .container {
      align-items: center;
    }
  }
  .navbar {
    .navbar-toggler {
      padding: 0;
      border: none;
      outline: none;
      width: 30px;
      height: 30px;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;
      span {
        margin-bottom: 5px;
        background-color: #f51c1e;
        width: 100%;
        height: 4px;
        border-radius: 5px;
        transform-origin: center center;
        position: relative;
        transition-duration: 0.5s;
        display: inline-block;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .navbar.toggle-btn-open {
    span {
      background-color: #f51c1e;
    }
    span:first-child {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 8px;
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:last-child {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -11px;
    }
  }
  .navbar-collapse.show {
    max-height: 400px;
    transition: max-height 0.3s ease-in;
  }
  .navbar-collapse {
    margin: 0px -15px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background: #fff;
    .navbar-nav {
      text-align: center;
      margin-left: 0 !important;
      width: 100%;
      text-align: center;
      li.nav-item {
        margin: 0;
        margin-bottom: 25px;
        display: inline-block;
        a {
          display: inline-block;
          &::before {
            width: 150%;
            left: -25%;
            display: none;
          }
          &:hover::before {
            max-width: 150%;
          }
        }
      }
      li.nav-item.active {
        a {
          &::before {
            max-width: 150%;
          }
        }
      }
    }
  }
  nav.navbar .navbar-nav .nav-item.languege-dropdown a::after {
    top: 15px;
  }
  nav.navbar .navbar-nav .nav-item.languege-dropdown a {
    padding: 11px 20px 11px 7px;
  }
}
