@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('../../../node_modules/bootstrap/dist/css/bootstrap.css');

@font-face {
  font-family: 'PhotoWallSansShadow';
  src: url('../fonts/PhotoWallSansShadow-Regular.eot');
  src: url('../fonts/PhotoWallSansShadow-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PhotoWallSansShadow-Regular.woff2') format('woff2'), url('../fonts/PhotoWallSansShadow-Regular.woff') format('woff'),
    url('../fonts/PhotoWallSansShadow-Regular.ttf') format('truetype'),
    url('../fonts/PhotoWallSansShadow-Regular.svg#PhotoWallSansShadow-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.modal {
  height: 100vh;
}
body {
  height: 79.6px;
  font-family: 'Lato', sans-serif;
}
.common-title {
  position: relative;
  display: inline-block;
  font-family: 'PhotoWallSansShadow';
  font-size: 70px;
  line-height: 1.2;
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    top: 105%;
    left: 0;
    width: calc(110%);
    left: -5%;
    content: '';
    background: #000;
    height: 5px;
  }
}
.common-title.red {
  color: #ff5555;
  &::after {
    background: #ff5555;
  }
}
.view-more-btn {
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-top: 50px;
  display: inline-block;
  color: #ff5555;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 900;
  &:hover,
  &:focus {
    color: #000;
  }
}
.text-right {
  text-align: right;
}
.package-info-link {
  font-size: 16px;
  line-height: 1.4;
  color: #007bf2;
  font-weight: 500;
}
.my-games-header-sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  h4 {
    margin: 0;
    color: #ff5555;
    font-weight: 700;
    text-transform: uppercase;
  }
  .view-more-btn {
    margin-top: 0;
  }
}
.log-out-popup-sec {
  max-width: 600px !important;
}
.log-out-popup {
  padding: 50px 0px;
  text-align: center;
  h2 {
    font-weight: 700;
  }
  button {
    position: relative;
    z-index: 1;
    background: #000 url('../images/gradiant-bg.png') no-repeat center center/cover;
    margin: 0px auto;
    width: 100px;
    height: 50px;
    text-transform: uppercase;
    border-radius: 40px;
    border: 3px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border: 2px solid transparent;
    margin-top: 25px;
    span {
      font-size: 20px;
      line-height: 40px;
      color: #fff;
    }
    &:hover {
      border-color: #ff5555;
    }
  }
  .logout-btns {
    margin-top: 25px;
    button {
      margin: 0 10px;
    }
  }
}
.my-games-header-sec {
  position: relative;
  &:after {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: #ff5555;
  }
  .view-more-btn {
    text-decoration: none;
    margin: 0;
    font-size: 18px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffefef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff5555;
}
.error-msg {
  font-size: 14px;
  line-height: 1.4;
  color: #ff5555;
  font-weight: 700;
  margin-top: 5px;
  text-align: left;
}
.home-banner-sec {
  form {
    .error-msg {
      color: #000;
    }
  }
}
.lock-info {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 700;
  color: #8b8b8b;
  display: none;
  span {
    color: #ff5555;
  }
}
.gameinfo-popup {
  padding: 5px;
  .game-info-slider-sec {
    h4 {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 900;
      color: #ff5555;
      margin-bottom: 20px;
    }
    .gameinfoslide {
      padding: 0px 15px;
      .game-info-img {
        padding-top: 111%;
      }
      video {
        height: 300px;
        position: relative;
      }
    }
    .slick-arrow.slick-prev {
      width: 25px;
      height: 25px;
      background: url('../images//red-arrow-img.png') no-repeat center center/auto 100%;
      top: 50%;
      transform: translateY(-50%);
      transform: scale(1);
      transition: 0.3s;
      &::before {
        display: none;
      }
      &:hover {
        transform: scale(1.2);
        transition: 0.3s;
      }
    }
    .slick-arrow.slick-next {
      width: 25px;
      height: 25px;
      background: url('../images//red-arrow-img.png') no-repeat center center/auto 100%;
      transform: translateY(-50%) rotate(180deg);
      &::before {
        display: none;
      }
      &:hover {
        transform: translateY(-50%) rotate(180deg) scale(1.2);
        transition: 0.3s;
      }
    }
  }
  .game-info-sec {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .game-info-left-sec {
      width: 25%;
      img {
        width: 100px;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin-top: 15px;
        li {
          margin-bottom: 10px;
          font-size: 17px;
          label {
            font-weight: 600;
            width: 100%;
          }
          span {
            font-weight: 400;
          }
        }
      }
    }
    .game-info-right-sec {
      width: 75%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      .game-info-desc {
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 15px;
        p {
          font-size: 18px;
          line-height: 1.5;
        }
      }
      a {
        cursor: pointer;
      }
    }
  }
  h2 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 700;
    color: #ff5555;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}
.modal-dialog {
  max-width: 70%;
  top: 50%;
  transform: translateY(-50%) !important;
  transition: none;
  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
    padding: 30px 35px;
    background: #fff;
    .common-close-btn {
      position: fixed;
      top: 15px;
      right: 20px;
      background: transparent;
      border: none;
      outline: none;
      max-width: 25px;
      padding: 0;
      z-index: 99;
      img {
        max-width: 100%;
      }
    }
  }
}
.terms-privacy-sec {
  padding: 50px 0px;
  min-height: 60vh;
  .common-title {
    margin-bottom: 40px;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 10px;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.4;
  }
}
.game-list-popup {
  .modal-content {
    padding: 10px 20px;
  }
  .my-games-sec {
    padding: 0;
    min-height: 475px;
    .game-list {
      padding-top: 30px;
      max-height: 450px;
      overflow-y: auto;
      margin-top: 0;
      overflow-x: hidden;
      li {
        cursor: pointer;
        div {
          flex-direction: column;
          img {
            margin-right: 0;
            max-width: 100%;
          }
          h3 {
            width: 100%;
            margin-bottom: 0;
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
          }
          ul {
            padding: 0;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            li {
              margin: 0;
              padding: 0;
              width: 100%;
              border: none;
              transform: scale(1);
              font-size: 14px;
              margin-bottom: 10px;
              word-break: break-all;
              span {
                color: #ff5555;
                font-weight: 700;
              }
              p {
                margin-bottom: 0;
              }
            }
          }
        }
        .lock-img {
          width: 80px;
          height: 80px;
        }
      }
      li.lock-game {
        cursor: not-allowed;
        .game-list-header {
          opacity: 0.4;
        }
      }
    }
  }
}
.game-list-popup.modal-dialog .modal-content .modal-body {
  padding: 0;
}
.modal-dialog .modal-content::-webkit-scrollbar-track {
  margin: 5px 0px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.8);
  z-index: 9999;
  img {
    max-width: 250px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .common-title {
    font-size: 50px;
  }
  .modal-dialog {
    max-width: 95%;
  }
  .gameinfo-popup {
    .game-info-slider-sec {
      .gameinfoslide {
        video {
          height: 277px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .common-title {
    font-size: 50px;
  }
  .games-sec {
    padding-bottom: 250px;
  }
  .view-more-btn {
    margin-top: 20px;
    font-size: 22px;
  }
  .modal-dialog {
    max-width: 95%;
  }
  .gameinfo-popup {
    .game-info-slider-sec {
      .gameinfoslide {
        video {
          height: 198px;
        }
      }
    }
    .game-info-sec {
      .game-info-left-sec {
        img {
          width: 80px;
        }
        ul {
          margin-top: 10px;
          margin-bottom: 0;
          li {
            font-size: 13px;
          }
        }
      }
      .game-info-right-sec {
        .game-info-desc {
          max-height: 200px;
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .common-title {
    font-size: 40px;
    &::after {
      width: 100%;
      left: 0;
    }
  }
  .view-more-btn {
    font-size: 20px;
    margin-top: 25px;
  }
  .modal-dialog {
    max-width: 90%;
    z-index: 9999;
    margin: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-content {
      max-height: 75vh;
      padding: 10px 15px;
      .common-close-btn {
        width: auto;
        max-width: unset;
        top: 10px;
        img {
          max-width: 15px;
        }
      }
    }
  }
  .log-out-popup-sec {
    max-width: 90% !important;
  }
  .gameinfo-popup {
    h2 {
      font-size: 25px;
      max-width: 90%;
      margin-bottom: 10px;
    }
    .game-info-slider-sec {
      .gameinfoslide {
        padding: 0px 8px;
        video {
          height: 220px;
        }
        .game-info-img {
          padding-top: 167%;
        }
      }
    }
    .game-info-sec {
      flex-direction: column;
      .game-info-left-sec {
        width: 100%;
        img {
          width: 80px;
        }
        ul {
          margin-top: 10px;
          margin-bottom: 0;
          li {
            font-size: 13px;
          }
        }
      }
      .game-info-right-sec {
        width: 100%;
        .game-info-desc {
          max-height: unset;
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .terms-privacy-sec {
    padding: 30px 0px;
    p,
    ul {
      li {
        font-size: 14px;
      }
    }
  }
  .my-games-header-sec {
    h4 {
      margin: 5px 0px;
    }

    .view-more-btn {
      margin: 5px 0px;
    }
  }
}

//
//
//
//
//
// my stype

.project-sec {
  margin-top: 79.6px;
  .project-container {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
  }
}

.outer-property-card {
  height: 30vh;
  // max-width: ;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.home_contact_us_page {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .home_contact_us_page {
    margin-top: 30px !important;
    width: fit-content;
    margin: 10px;
  }
}

@media (max-width: 767px) {
  #root {
    width: fit-content;
  }
}

.home_banner {
  .carousel-caption {
    margin-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .home_banner {
    color: black;
    h1 h4 {
      font-size: 12px;
    }
    .carousel-caption {
      margin-bottom: 0px;
    }
  }
}


