.footer {
  padding: 160px 0px 50px 0px;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: url('../../images/red-cloud-bg.png') no-repeat top center/cover;
    content: '';
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 350px);
    content: '';
    background: #fce0e0;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  h3 {
    margin-bottom: 30px;
    color: #ff5555;
    font-weight: 900;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      font-size: 16px;
      line-height: 1.2;
      color: #000;
      font-size: 20px;
      line-height: 1.2;
      color: #000;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      a {
        color: #000;
        &:hover {
          color: #ff5555;
        }
      }
      img {
        margin-right: 15px;
      }
    }
  }
  ul.info-list {
    li {
      a {
        text-decoration: none;
      }
    }
  }
}
.footerlogo {
  max-width: 180px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    ul {
      li {
        font-size: 16px;
        img {
          max-width: 20px;
        }
      }
    }
  }
  .footerlogo {
    max-width: 150px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 130px 0px 50px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    ul {
      li {
        font-size: 20px;
        text-align: center;
        justify-content: center;
        img {
          max-width: 18px;
        }
      }
    }
    .col-md-3 {
      img {
        max-width: 120px;
      }
    }
    h3 {
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .container {
      flex-direction: column;
      gap: 30px;
    }
  }
}
