.about-us-sec {
  padding: 30px 0px 50px;
  margin-top: 79.6px;
  img {
    max-width: 90%;
    margin: 0px auto;
    border-radius: 25px;
  }
  p {
    font-size: 20px;
    line-height: 28px;
  }
  .common-title {
    margin-bottom: 80px;
  }
  .about-img {
    padding-top: 100%;
    border-radius: 25px;
  }
  .slick-dots {
    bottom: -50px;
    right: 0;
    left: unset;
    width: 100%;
    display: inline-block;
    position: absolute;
    z-index: 3;
    text-align: center;
  }
  .slick-dots li {
    width: 50px;
    height: auto;
    background: #bdbdbd;
  }
  .slick-dots li button {
    padding: 0px;
    width: 0%;
    height: 7px;
    background: #ff5555;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    content: '';
  }
  li.slick-active button {
    width: 100%;
    transition-duration: 5s;
    -webkit-transition-duration: 5s;
    transition-timing-function: linear;
    -webkit-transition-timing-function: linear;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .about-us-sec {
    padding: 30px 0px;
    .common-title {
      margin-bottom: 40px;
    }
    p {
      font-size: 20px;
      line-height: 28px;
    }
    img {
      max-width: 100%;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-us-sec {
    padding: 30px 0px;
    .common-title {
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
    img {
      max-width: 100%;
    }
    .about-img {
      padding-top: 150%;
    }
    .slick-dots li {
      width: 30px;
    }
  }
}
@media (max-width: 767px) {
  .about-us-sec {
    padding: 30px 0px;
    .common-title {
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
    img {
      max-width: 100%;
      max-height: 250px;
    }
    .row {
      flex-direction: column-reverse;
    }
    .about-img {
      padding-top: 100%;
    }
    .about-us-img {
      padding: 0px 10px;
      margin-bottom: 0px;
    }
    .slick-slider {
      margin-bottom: 50px;
    }
    .slick-dots {
      bottom: -30px;
    }
  }
}
